import React from 'react'
import { Helmet } from 'react-helmet'


function TermsCondition() {
  return (
    <>
     <Helmet>
        <title>Terms and Conditions Of Monktech.Us</title>
        <meta name='description' content="Understand how Monktech.Us works and what terms you need to go through before using our site. It’s compulsory to read it!" />
      </Helmet>
    <section className="post-content my-4">
            <div className="container">

                <div className="row">
                    <div className="col-lg-10 col-12 mx-auto shadow p-4">
                        <div className="row ltst-pddng ">
                            <div className="col-lg-12 col-12">
                                <h2 className="text-center py-3 ">Terms and Conditions </h2>

                        <div className="content">
                           
                            <p className="mb-2 text-justify">The use of ‘We,’ ‘Our,’ ‘Us,’ or ‘Monktech.us is the representation of Monktech.us only. You should not confuse it anyway.</p>
                            <p className="mb-2 text-justify">This is a ‘Terms and Conditions’ page of Monktech.us. This page is essential and should be read before using Monktech.us.</p>
                            <p className="mb-2 text-justify">It contains crucial information about how we work and what you should be careful about while floating over our website.</p>
                            <p className="mb-2 text-justify">Also, if you start using our website without going through the Terms and Conditions page, you will already agree to the conditions we provide here.</p>
                            <h3 className="py-3">Content info </h3>
                            <p className="mb-2 text-justify">Every piece of information on Monktech.us is an original work, and the use of this information should be to eradicate the problem only. No one is allowed to steal the information given in our write-ups for their own benefit. Doing so will be a punishable act.</p>
                            <p className="mb-2 text-justify">Experts review each blog on the website and then put it on the site. As we are very specific about our content, each piece of information has gone through a tough check to make it reliable and good for people out there.</p>
                            <p className="mb-2 text-justify">You can trust the process, steps, and other information outlined on a particular page to remove the hurdle from your device or service.</p>
                            <p className="mb-2 text-justify">You might find some links on the pages of our site. This will belong to either the official page of the particular service or device or to guide you about the issue clearly.</p>
                            <p className="mb-2 text-justify">If you navigate to a third-party site through our write-ups, you will solely be responsible for using that information.</p>
                            <p className="mb-2 text-justify">We take responsibility for our content, which is on our website only. Using third-party suggestions or visiting them is your own responsibility.</p>
                            <h3 className="py-3">Use of Blogs</h3>
                            <p className="mb-2 text-justify">We already mentioned that our informative blog is an easy guide through which a user can fix his problems related to various online services and devices. Thus, it should be used only for finding answers to your query.</p>
                            <p className="mb-2 text-justify">Since we review each piece of information before putting it on our website, it doesn’t mean we will provide you monetary help if you find any kind of problem.</p>
                            <p className="mb-2 text-justify">The use of our information is your own concern, and we, Monktech.us, are not responsible for that.</p>
                            <h3 className="py-3">Terms</h3>
                            <p className="mb-2 text-justify">We do not take responsibility for your usage of our information. Any loss is at your own discretion. We are here to help you in the best possible manner.</p>
                            <p className="mb-2 text-justify">Remember to use the information as a guide only. You should not make it a rule for your problems. We also do not force you to stick to our information only. You are free to choose anyone to help you with your problems with your device.</p>
                            <p className="mb-2 text-justify">Some articles on Monktech.us might be for sharing points of view; thus, it should not be used as a final decision. Be free to wander over the web and look for the best help.</p>
                            <h3 className="py-3">Illegal Use</h3> 
                            <p className="mb-2 text-justify">Do not use our blog information or any content published on our website for illegal purposes. We do not support that. Anyone doing so will be liable for serious punishments, as per the law.</p>
                            <p className="mb-2 text-justify">The following points will better inform you about things you should avoid doing on/with our website:</p>

                            <h3 className="py-3">You are not allowed to:</h3>
                            <ol>
                            <li>	Collect or extract content from the website using any method.</li>
                            <li>Do anything that goes against the rules stated in the Terms of Use.</li>
                            <li>Try to figure out or access the inner workings of the website's code.</li>
                            <li>Use spam or auto-responder tools on the website.</li>
                            <li>Use automated tools to gather information from the website.</li>
                            <li>Overload the website's systems or disrupt its functioning.</li>
                            <li>Try to bypass any restrictions or access controls on the website.</li>
                            </ol>




                        
                        </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </section>
    </>
  )
}

export default TermsCondition