import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
// import Header from "./header";
// import Footer from "./footer";
import { Carousel } from "react-bootstrap";
// import "./blog.css";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import 'bootstrap/dist/css/bootstrap.css';
// import dynamic from "next/dynamic";
 import axios from "axios";
// import Link from "next/link";
import he from "he";
import { Helmet } from 'react-helmet';

function Homepage() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [blogData, setBlogData] = useState([]);
    const next = useNavigate();
    const handleClick = (slug) => {
      console.log('slug', slug)
      next(`/blog`, { state: { slug: slug } });
    };
  
  
    useEffect(() => {
      axios
        .get("https://monktech.us/demo/wp-json/wp/v2/posts", {})
        .then((res) => {
          setBlogData(res?.data);
        });
    }, []);
  
    const handleBlog =((id)=>{
      console.log('id', id)
  
    })
  return (
    <>
       <Helmet>
        <title> Monktech - Simplifying Tech and Unlocking Solutions</title>
        <meta name='description' content="Discover seamless tech solutions at Monktech.us! We simplify complexities across devices, social media, Google services, streaming platforms, and more. Trust Monktech for quick and reliable tech assistance!" />
      </Helmet>
      {/* <Header /> */}

      {/* ====off-canvas=== */}
      
      <div
        className="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Offcanvas
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {/* <!--side-menu---> */}
          <div className="side-menu">
            <nav className="animated bounceInDown">
              <ul>
                {/* <!-- <li><a href='#profile'>Profile</a></li>
              <li><a href='#message'>Messages</a></li> --> */}
                <li className="sub-menu">
                  <a href="#settings">
                    Settings<div className="fa fa-caret-down right"></div>
                  </a>
                  <ul>
                    <li>
                      <a href="#settings">Account</a>
                    </li>
                    <li>
                      <a href="#settings">Profile</a>
                    </li>
                    <li>
                      <a href="#settings">Secruity &amp; Privacy</a>
                    </li>
                    <li>
                      <a href="#settings">Password</a>
                    </li>
                    <li>
                      <a href="#settings">Notification</a>
                    </li>
                  </ul>
                </li>
                <li className="sub-menu">
                  <a href="#message">
                    Help<div className="fa fa-caret-down right"></div>
                  </a>
                  <ul>
                    <li>
                      <a href="#settings">FAQ's</a>
                    </li>
                    <li>
                      <a href="#settings">Submit a Ticket</a>
                    </li>
                    <li>
                      <a href="#settings">Network Status</a>
                    </li>
                  </ul>
                </li>
                <li className="sub-menu">
                  <a href="#message">
                    Help<div className="fa fa-caret-down right"></div>
                  </a>
                  <ul>
                    <li>
                      <a href="#settings">FAQ's</a>
                    </li>
                    <li>
                      <a href="#settings">Submit a Ticket</a>
                    </li>
                    <li>
                      <a href="#settings">Network Status</a>
                    </li>
                  </ul>
                </li>
                <li className="sub-menu">
                  <a href="#message">
                    Help<div className="fa fa-caret-down right"></div>
                  </a>
                  <ul>
                    <li>
                      <a href="#settings">FAQ's</a>
                    </li>
                    <li>
                      <a href="#settings">Submit a Ticket</a>
                    </li>
                    <li>
                      <a href="#settings">Network Status</a>
                    </li>
                  </ul>
                </li>
                <li className="sub-menu">
                  <a href="#message">
                    Help<div className="fa fa-caret-down right"></div>
                  </a>
                  <ul>
                    <li>
                      <a href="#settings">FAQ's</a>
                    </li>
                    <li>
                      <a href="#settings">Submit a Ticket</a>
                    </li>
                    <li>
                      <a href="#settings">Network Status</a>
                    </li>
                  </ul>
                </li>
                <li className="sub-menu">
                  <a href="#message">
                    Help<div className="fa fa-caret-down right"></div>
                  </a>
                  <ul>
                    <li>
                      <a href="#settings">FAQ's</a>
                    </li>
                    <li>
                      <a href="#settings">Submit a Ticket</a>
                    </li>
                    <li>
                      <a href="#settings">Network Status</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#message">Logout</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {/* End Header  */}
      {/* <!-- ======= Hero Section ======= --> */}

      <section className="hero-slider-sec">
        <div className="container-auto">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="hero-slider">
                

                <Carousel>
                  {blogData.slice(0, 3).map((item, index) => (
                    <Carousel.Item interval={2000} key={index}>
                      <div >
                        <img
                          src={item?.fimg_url}
                          className="d-block w-100 hero-slider-img"
                          alt={`hero image${index + 1}`}
                        />
                        <Carousel.Caption>
                        <Link to={`/blog/${item.slug}`}>
                          {he.decode(item?.title?.rendered)}
                          </Link>
                        </Carousel.Caption>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>

              
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-12">
              <div className="row ipade-view">
                {blogData.slice(0, 4).map((item, index) => (
                  <div className="col-lg-6 col-12 pdng-mbl" key={index}>
                    <div className="hero-img">
                      <div className="img-on-number">
                        <a href="#">
                          <span>{index + 1}</span>
                        </a>
                      </div>
                      <img
                        className="mx-auto img-fluid d-block w-100"
                        src={item?.fimg_url}
                        alt={`hero image${index + 1}`}
                      />
                      <div className="img-on-txt">
                        {/* <a href="#" onClick={()=>handleBlog(item?.id)}> */}
                        <Link to={`blog/${item.slug}`}>
                          <p>
                            {he.decode(item?.title?.rendered)}
                          </p>
                          </Link>
                        {/* </a> */}
                      </div>
                    </div>
                  </div>
                ))}
                
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======= Hero Section-end==== --> */}

      {/* <!-- ======= News Section-satrt===--> */}

      <section className="news-sec">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="heading-box">
              <span>
                <i className="fa fa-bolt" aria-hidden="true"></i>
              </span>
              <h2>Social</h2>
            </div>
            <div className="row" ></div>
            {blogData?.map((item, index) => {
              return (
                <>
                
                  {item?.categories?.includes(6) ? (
                    <>
                    
                     <div className="col-md-4 social-category" key={index}>
                            <div className="box-item rounded mb-3" >
                                <div className="blog-img">
                                <img src={item?.fimg_url} class="img-fluid rounded shadow " alt="blog" />
                                </div>
                                <div className="content p-2">
                                  <div className="title py-3 px-3 text-center">
                                    <h3><Link to={`blog/${item.slug}`}>{he.decode(item?.title?.rendered)}</Link></h3>
                                  </div>
                                </div>
                            </div>
                        </div>
                        
                    </>
                    
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
            
          </div>
        </div>
      </section>

      {/* <!-- ======= News Section-end==== --> */}

      {/* <!-- ======= LATESTpost Section-satrt=--> */}

      <section className="category-home">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="heading-box">
               
                <h2>TV</h2>
              </div>
              {blogData?.slice(0, 7)?.map((item, index) => {
                return (
                  <>
                    {item?.categories?.includes(7) ? (
                      <>
                        <div className="row ltst-pddng" key={index}
                        >
                          <div className="col-lg-7 col-12">
                            <div className="latest-post">
                              <img
                                className="mx-auto img-fluid d-block"
                                src={item?.fimg_url}
                                alt={`latest post image${index + 1}`}
                              />
                            </div>
                          </div>
                          <div className="col-lg-5 col-12">
                            <div className="latest-image-content">
                             
                              <h4><Link to={`blog/${item.slug}`}>{he.decode(item?.title?.rendered)}</Link></h4>
                              <p>
                                {he
                                  .decode(item?.excerpt?.rendered)
                                  .replace(/<\/?p>/g, "")}
                              </p>
                             
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
            {/* <!--tabs--> */}
            <div className="col-lg-4 col-12">
              <div className="post-tabs">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <Link href="#profile1">
                      <button
                        className="nav-link tbs active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home1"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Home
                      </button>
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link tbs"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile1"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Profile
                    </button>
                    {/* <Link href="/blog-categorie">Home</Link> */}
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link tbs"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact1"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Contact
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home1"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {blogData?.slice(0, 7)?.map((item,index)=>{
                      return (<>{item?.categories?.includes(132) ? (<>
                       <div className="row tbs-pdng" key={index}>
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src={item?.fimg_url}
                            alt={`latest post image${index + 1}`}
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                        <Link href={`bloglist/${item.slug}`}>
                          <h5>
                          {he.decode(item?.title?.rendered)}
                          </h5>
                          </Link>
                         
                        </div>
                      </div>
                    </div></>):(<></>)}</>)
                    })}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile1"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    {/* <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div> */}
                    <h1>Hello</h1>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact1"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row tbs-pdng">
                      <div className="col-lg-5 col-12">
                        <div className="tbs-post-img">
                          <img
                            className="mx-auto img-fluid d-block"
                            src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/10/53154206617_77f1de5022_o.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-12 d-flex align-items-center">
                        <div className="tbs-content">
                          <h5>
                            Android Phone Vibrating Randomly? Here's How To Fix
                            It
                          </h5>
                          <p>
                            <span>02 days</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======= LATESTpost Section-end===--> */}

      {/* <!--footer-start--> */}
      {/* <Footer /> */}
      {/* <!--footer-end--> */}
    </>
  )
}

export default Homepage