import React from 'react'
import { Container, Dropdown, DropdownButton, Form, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import about from '../components/about/About';
import contact from '../components/contact/Contact';
import privacypolicy from '../components/privacy-policy/PrivacyPolicy';
import termscondition from '../components/terms-condition/TermsCondition';

function Header() {
    const id = 6;
  const id1 = 7;
  const id2 = 8;
  return (
    <>
    <header>
    {[false,].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary ">
          <Container fluid>
          <div className="col-lg-2">
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          </div>
          <div className='col-lg-8'>
          <div className="logo text-center">
          <Link to="/">
         <img width="250px"  src="https://monktech.us/demo/wp-content/uploads/2024/01/Montech-2.webp" className="mx-auto" alt=""/>
         </Link>
        </div>
          </div>
          <div className="col-lg-2">
       
       </div>
           
            
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              backdrop="static"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Link to="/">Home</Link>
                  <Link to="/about">About Us</Link>
                  <Link to="/blogs">Blog</Link>
                  <Link to="/contact-us">Contact Us</Link>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                  <Link to="/terms-conditions">Terms Conditions</Link>
                 
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        
      ))}
      <div className="bottom-header">
        <div className="container-fluid">
          <div className="row">
            <div className="header-menu">
              <ul className="navbar-nav flex-row flex-wrap d-flex justify-content-center">
                <li className="nav-item">
                <Link to={`/blog-categorie/${id}`}>
                Social
                          </Link>
                  {/* <a className="nav-link active" href="/blog-categorie">
                    trending
                  </a> */}
                </li>
                <li className="nav-item">
                <Link to={`/blog-categorie/${id1}`}>
                TV
                          </Link>
                  {/* <a className="nav-link active" href="/blog-categorie">
                    trending
                  </a> */}
                </li>
                <li className="nav-item">
                <Link to={`/blog-categorie/${id2}`}>
                Email
                          </Link>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="#">
                    pc otimization{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    microsoft excel
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    windows 11{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="#">
                    xbox
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    playstation{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    cord cutting
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    </>
  )
}

export default Header