import React from 'react'
import { Link } from 'react-router-dom';
import './blog.css'



function Footer() {
    const currentYear = new Date().getFullYear();
  return (
    <>
    <footer className="footer-sec">
<div className="container">
  <div className="row">
    <div className="col-lg-12 col-12">
      <div className="footer-box">
        

        <div className="row footer-menu">
          <div className="col-lg-10 col-12 m-auto">
            <ul className="navbar-nav flex-row flex-wrap d-flex justify-content-center footer-list">
              
              <li className="nav-item">
              <Link to="/about">About Us</Link>
              </li>
              <li className="nav-item">
              
              </li>
              
              <li className="nav-item">
              <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              
              <li className="nav-item">
              <Link to="/terms-conditions">Terms And Conditions</Link>
               
              </li>
              
              
              <li className="nav-item">
              <Link to="/contact-us">Contact Us</Link>
               
              </li>
              
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="copyright">
  <p> @ { currentYear } copyright.</p>
</div>
</footer>
    </>
  )
}

export default Footer