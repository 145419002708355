import React from "react";
import { useLocation, useParams } from "react-router-dom";
import he from "he";
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
function Blog() {
  const { data } = useParams();

  const [individualPost, setIndividualPost] = useState([]);
  console.log("data", individualPost?.title?.rendered)
  const [param, setParam] = useState(data);
  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`https://monktech.us/demo/wp-json/wp/v2/posts?slug=${data}`, {})
        .then((res) => {
          setIndividualPost(res?.data);
        });
    }, 1000);
  }, [param]);
  return (
    <>
    <Helmet>
        <title>{individualPost[0]?.title?.rendered}</title>
        <meta name='description' content={individualPost[0]?.yoast_head_json?.description}/>
      </Helmet>
      <main id="main">
        {/* <!-- End Breadcrumbs Section --> */}
        {/* <!-- ======= LATESTpost Section-satrt=--> */}
        <section className="letest-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-12 mx-auto">
                <div className="row ltst-pddng shadow rounded">
                  <div className="col-lg-12 col-12">
                    <div className="latest-post">
                      <img
                        className=" img-fluid d-block"
                        src={individualPost[0]?.fimg_url}
                        alt="latest post image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="latest-image-content">
                      {individualPost && individualPost[0]?.title && (
                        <h1>{he.decode(individualPost[0]?.title.rendered)}</h1>
                      )}
                      {individualPost && individualPost[0]?.excerpt && (
                        <div className="entry-content">
                        <p>
                          {" "}
                          {he
                            .decode(individualPost[0]?.excerpt?.rendered)
                            .replace(/<\/?p>/g, "")}
                        </p>
                        </div> 
                      )}

                      {/* {individualPost && individualPost.content && (
                      <p> {he
                                  .decode(individualPost?.content?.rendered)
                                  .replace(/<\/?p>/g, "")}
                      </p>)} */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: individualPost[0]?.content?.rendered,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ======= LATESTpost Section-end===--> */}
      </main>
    </>
  );
}

export default Blog;
