import "bootstrap/dist/css/bootstrap.min.css";
import { Routes as Switch, Route, useParams } from "react-router-dom";
import Layout from "./layout/Layout";
import Contact from "./components/contact/Contact";
import Homepage from "./components/home/Homepage";
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";
import TermsCondition from "./components/terms-condition/TermsCondition";
import About from "./components/about/About";
import Blog from "./components/blog/Blog";
import Blogs from "./components/blogs/blogs";
import BlogCategorie from "./components/blogcategorie/BlogCategorie";


function App() {


  return (
    <>

      <Switch>

        <Route path='/' element={<Layout />} >
          <Route index element={<Homepage />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-conditions' element={<TermsCondition />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blog/:data' element={<Blog />} />
          <Route path='/blog-categorie/:data' element={<BlogCategorie />} />
        </Route>
      </Switch>
    </>
  );
}

export default App;
