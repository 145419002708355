import React from 'react'
import { Helmet } from 'react-helmet'


function Contact() {
  return (
    <>
 <Helmet>
        <title>Monktech.Us – Contact Us Page</title>
        <meta name='description' content="This page is for you to ask questions, provide suggestions, and voice your concerns about Monktech.Us. You can contact us via various options!" />
      </Helmet>

      <section className="post-content my-4">
            <div className="container">

                <div className="row">
                    <div className="col-lg-10 col-12 mx-auto shadow p-4">
                        <div className="row ltst-pddng ">
                            <div className="col-lg-12 col-12">
                                <h2 className="text-center py-3 ">Contact Us</h2>

                        <div className="content">
                            <p className="mb-2 text-justify">You can message us if you have any questions about our website, blogs/articles, or anything else in general. You can write us your suggestions, describing improvements and how you found the information. For any purpose, you can contact us using the following options. Please choose what fits for you:</p>
                            

                        </div>

                        <div className="row ltst-pddng ">
                        <div className="col-md-8 col-12 mx-auto">

                            <h3 className="py-3 text-center text-danger"> Get In Touch</h3>


                            <form action="" id="">
                            <div className="mb-3">
                              
                              <input type="Name" placeholder="Full Name" className="form-control" id="name" required/>
                              
                            </div>

                            <div className="mb-3">
                              
                              <input type="Email" placeholder="Email" className="form-control" id="email" required/>
                              
                            </div>

                            <div className="mb-3">
                              
                              <input type="Mobile" placeholder="Mobile" className="form-control" id="email" required/>
                              
                            </div>

                            <div className="mb-3">
                            
                              <input type="Mobile" placeholder="Mobile" className="form-control" id="email" required/>
                              
                            </div>

                            <div className="mb-3">
                            

                                <textarea type="text" rows="5" className="form-control"  placeholder="Type..." />
                            </div>


                            <div class="col-auto">
                            <button type="submit" class="w-100 btn btn-success px-3 py-1">Submit</button>
                          </div>
                            </form>
                        </div>

                        </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </section>
    </>
  )
}

export default Contact