import React from 'react'
import { Helmet } from 'react-helmet'

function PrivacyPolicy() {
  return (
    <>
    <Helmet>
        <title>Privacy Policy Section For Monktech.Us</title>
        <meta name='description' content="Our privacy policy depicts how users’ data is stored, used, and kept safe with us. Must read before making use of Monktech.Us!" />
      </Helmet>
    <section className="post-content my-4">
            <div className="container">

                <div className="row">
                    <div className="col-lg-10 col-12 mx-auto shadow p-4">
                        <div className="row ltst-pddng ">
                            <div className="col-lg-12 col-12">
                                <h2 className="text-center py-3 ">Privacy Policy </h2>

                        <div className="content">
                            <p className="mb-2 text-justify">Your privacy is our topmost priority. We want to keep you safe during your visit to our site. This is possible with the help of a dedicated privacy policy.</p>
                            <p className="mb-2 text-justify">Our privacy policy depicts how users’ data is stored, used, and kept safe with us.</p>
                            
                            <p className="mb-2 text-justify">While visiting our site, we hold some of your information to improve your experience on our site. This makes your visit easier, such as opening our web page quickly, considering your preferences, offering relative guides, etc.</p>
                            <p className="mb-2 text-justify">How do we collect users’ information?</p>
                            <p className="mb-2 text-justify">We acquire some of the information about users’ visits on our platform. This is done in the following ways:</p>

                            <h3 className="py-3">Cookies:</h3>
                            <p className="mb-2 text-justify">We hold some cookies to discover your preferences, interests, and how you want help from our site.</p>
                           
                            <p className="mb-2 text-justify">‘Cookies holding’ is a common phenomenon nowadays and is for helping purposes. Acquiring cookies means you will find the information about users and can help users, such as in page loading time, preferences, etc.</p>
                            <p className="mb-2 text-justify">When you keep some adjustments on our site, we keep that stored in the form of cookies, and when you need it in the future, you are given that as it is.</p>

                           
                           
                            <h3 className="py-3">Data sharing and Third Party</h3>

                            <p className="mb-2 text-justify">We are against the act of sharing or stealing users’ data visited on our site. We do not perform such acts and also do not appreciate it however.</p>
                            <p className="mb-2 text-justify">Your data is safe with us. We keep it for your benefit on our website: Monktech.us.</p>
                            <p className="mb-2 text-justify">Since Monktech.us works solely, it has nothing to do with third parties, nor about selling any data to them.</p>


                            <h3 className="py-3">How data is stored</h3>

                            <p className="mb-2 text-justify">Every time, for every visit, we keep track of your IP address and keep it safe with us on our servers for around 7 days. This data will be in use within 7 days, and it’s most likely to help when users revisit our website within the period.</p>
                            <p className="mb-2 text-justify">Our data storage happens in huge servers we have. These servers are encrypted and provide the safety of your data upfront.</p>
                            <h3 className="py-3">Changes Rights</h3>

                            <p className="mb-2 text-justify">It is openly said that we are free to change our Privacy Policy section at any time without informing anyone. The privacy policy is a matter of change from time to time, and thus, we want to make it updated every time the need arises.</p>
                            <p className="mb-2 text-justify">You, as a user, are responsible for visiting our ‘Privacy Policy’ section every time you land on our website.</p>
                            <h3 className="py-3">Contact Information</h3>

                            <p className="mb-2 text-justify">We are open to receiving your queries, doubts, or any suggestions you want to give us. For any purpose, kindly send your valuable concern to our official email address:  </p>
                            



                        
                        </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </section>
    </>
  )
}

export default PrivacyPolicy