import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";

// import "./blog.css";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import 'bootstrap/dist/css/bootstrap.css';
// import dynamic from "next/dynamic";
 import axios from "axios";
// import Link from "next/link";
import he from "he";
import { Helmet } from 'react-helmet';

function Blogs() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [blogData, setBlogData] = useState([]);
    const next = useNavigate();
    const handleClick = (slug) => {
      console.log('slug', slug)
      next(`/blog`, { state: { slug: slug } });
    };
  
  
    useEffect(() => {
      axios
        .get("https://monktech.us/demo/wp-json/wp/v2/posts", {})
        .then((res) => {
          setBlogData(res?.data);
        });
    }, []);
  
    const handleBlog =((id)=>{
      console.log('id', id)
  
    })
  return (
    <>
    <Helmet>
        <title> Your Gateway to Tech Wisdom and Quick Solutions</title>
        <meta name='description' content="Dive into Monktech's blog for expert insights and simplified solutions across devices, social media, Google services, streaming platforms, and more. Explore now for the latest tech updates and easy-to-follow guides!" />
      </Helmet>

      <section className="news-sec">
        <div className="container">
          <div className="row ">
            <div className="title">
            <h2 className='text-center py-2'>Our Blog</h2>
            </div>
            <div className="row" ></div>
            {blogData.map((item, index) => (
              
                    
                     <div className="col-md-4 social-category" key={index}>
                            <div className="box-item rounded mb-3" >
                                <div className="blog-img">
                                <img src={item?.fimg_url} class="img-fluid rounded shadow " alt="blog" />
                                </div>
                                <div className="content p-2">
                                  <div className="title py-3 px-3 text-center">
                                    <h3><Link to={`/blog/${item.slug}`}>{he.decode(item?.title?.rendered)}</Link></h3>
                                  </div>
                                </div>
                            </div>
                        </div>
                 
                        ))}
            
          </div>
        </div>
      </section>

     

      {/* <!--footer-start--> */}
      {/* <Footer /> */}
      {/* <!--footer-end--> */}
    </>
  )
}

export default Blogs