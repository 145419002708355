import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import he from "he";
import { Link, useParams } from 'react-router-dom';
function BlogCategorie() {
    const { data } = useParams();
    const [blogData, setBlogData] = useState([]);
    useEffect(()=>{
      axios
      .get(
        `https://monktech.us/demo/wp-json/wp/v2/posts?categories=${data}`,
        {}
      )
      .then((res) => {
        setBlogData(res?.data)
      });
    },[])
  return (
    <>
    <main id="main">
        {/* <!-- ======= Breadcrumbs Section ======= --> */}
        
        {/* <!-- End Breadcrumbs Section --> */}

        {/* <!-- ======= LATESTpost Section-satrt=--> */}
        <section className="category">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="title">
                  <h2 className='text-center fw-bold py-3 mt-5'>Category</h2>
                </div>
              <div className="row ltst-pddng pt-5">
                
                {blogData?.map((item, index) => {
                return (
                  <>
                    {/* {item?.categories?.includes(0) ? ( */}
                      <>

                      
                 
                      
                        <div className="col-md-4" key={index}>

                          <div class="box-item shadow rounded">

                          <div className="latest-post">
                              <img
                                className="mx-auto img-fluid d-block"
                                src={item?.fimg_url}
                                alt={`latest post image${index + 1}`}
                              />
                            </div>

                            <div className="latest-image-content p-3 ">
                              
                              
                              <h4><Link to={`/blog/${item.slug}`}>{he.decode(item?.title?.rendered)} </Link></h4>
                             
                              <p>
                                {he
                                  .decode(item?.excerpt?.rendered)
                                  .replace(/<\/?p>/g, "")}
                              </p>
                              
                            </div>
                          </div>

                        </div>

                     
                      
                       
                      </>
                    {/* ) : (
                      <></>
                    )} */}
                  </>
                );
              })}

                {/* <div className="row ltst-pddng">
                  <div className="col-lg-7 col-12">
                    <div className="latest-post">
                      <img
                        className="mx-auto img-fluid d-block"
                        src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/07/b2b51937.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                        alt="latest post image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-12">
                    <div className="latest-image-content">
                      <p>
                        <span>Electric Car</span>
                      </p>
                      <h4>Buying a Used EV? Don’t Skip These Steps</h4>
                      <p>
                        Buying a pre-owned Tesla or other EVs has become more
                        common, and here's what you need to know.
                      </p>
                      <ul>
                        <li>
                          <a href="#">By Cory Gunther</a>
                        </li>
                        <li>
                          <a href="#">13 hours ago</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row ltst-pddng">
                  <div className="col-lg-7 col-12">
                    <div className="latest-post">
                      <img
                        className="mx-auto img-fluid d-block"
                        src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/07/b2b51937.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                        alt="latest post image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-12">
                    <div className="latest-image-content">
                      <p>
                        <span>Electric Car</span>
                      </p>
                      <h4>Buying a Used EV? Don’t Skip These Steps</h4>
                      <p>
                        Buying a pre-owned Tesla or other EVs has become more
                        common, and here's what you need to know.
                      </p>
                      <ul>
                        <li>
                          <a href="#">By Cory Gunther</a>
                        </li>
                        <li>
                          <a href="#">13 hours ago</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row ltst-pddng">
                  <div className="col-lg-7 col-12">
                    <div className="latest-post">
                      <img
                        className="mx-auto img-fluid d-block"
                        src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/07/b2b51937.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                        alt="latest post image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-12">
                    <div className="latest-image-content">
                      <p>
                        <span>Electric Car</span>
                      </p>
                      <h4>Buying a Used EV? Don’t Skip These Steps</h4>
                      <p>
                        Buying a pre-owned Tesla or other EVs has become more
                        common, and here's what you need to know.
                      </p>
                      <ul>
                        <li>
                          <a href="#">By Cory Gunther</a>
                        </li>
                        <li>
                          <a href="#">13 hours ago</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row ltst-pddng">
                  <div className="col-lg-7 col-12">
                    <div className="latest-post">
                      <img
                        className="mx-auto img-fluid d-block"
                        src="https://static1.howtogeekimages.com/wordpress/wp-content/uploads/2023/07/b2b51937.jpg?q=50&fit=contain&w=1140&h=&dpr=1.5"
                        alt="latest post image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-12">
                    <div className="latest-image-content">
                      <p>
                        <span>Electric Car</span>
                      </p>
                      <h4>Buying a Used EV? Don’t Skip These Steps</h4>
                      <p>
                        Buying a pre-owned Tesla or other EVs has become more
                        common, and here's what you need to know.
                      </p>
                      <ul>
                        <li>
                          <a href="#">By Cory Gunther</a>
                        </li>
                        <li>
                          <a href="#">13 hours ago</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                 </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ======= LATESTpost Section-end===--> */}
      </main>
    </>
  )
}

export default BlogCategorie