import React from 'react'
import { Helmet } from "react-helmet";
function About() {
  return (
   <>
   <Helmet>
        <title>About Us Page – Monktech.us</title>
        <meta name='description' content="Monktech.us is a self-help spot for various device and online service glitches. You get rich blogs reviewed by experts to remove hurdles quickly. Read & Use!" />
      </Helmet>
   <section className="post-content my-4">
            <div className="container">

                <div className="row">
                    <div className="col-lg-10 col-12 mx-auto shadow p-4">
                        <div className="row ltst-pddng ">
                            <div className="col-lg-12 col-12">
                                <h2 className="text-center py-3 ">About Us </h2>

                        <div className="content">
                            <p className="mb-2 text-justify">Monktech.us is a website for users seeking help for their devices, and online services, such as social media, email, google services, streaming services, and more. Basically, we are a team of experienced experts who keep on working on picking problems, seeking solutions for them, and presenting them in a simple-to-read way.</p>
                            <p className="mb-2 text-justify">We strive to comfort users (who are taking advantage of any device or service) with our super easy informative guides. Our objective behind making this website is to let the user feel home-like when struggling with an issue.</p>
                            <h3 className="py-3">How do we provide information?</h3>
                            <p className="mb-2 text-justify">With the help of our EXPERIENCE TEAM, the answer is! With years of involvement in settling various issues and coming to many conclusions, we have understood the realm of setting up the device, fixing any service errors, and making things easy for users with our efforts.</p>
                            <p className="mb-2 text-justify">We choose an easy path when it comes to complex device issues. This not only makes users skim through the solutions more easily but is also a factor of trust building. Users would want to revisit our place (Monktech.us) for their queries in the future.</p>
                            <p className="mb-2 text-justify">To make you aware, we first select the issue, which could be of device – smartphone computer accessories, and then shortlist the best solutions. Then, we write the content that is easy to read and effective.</p>
                            <p className="mb-2 text-justify">To make you aware, we first select the issue, which could be of device – smartphone computer accessories, and then shortlist the best solutions. Then, we write the content that is easy to read and effective.</p>
                            <h3 className="py-3">What do we inform you about?</h3>
                            <p className="mb-2 text-justify">At Monktech.us, you will find various kinds of services and devices covered. This enlarged coverage of topics that we assist you with is given below for quick understanding:</p>
                            <h3 className="py-3">We provide information for:</h3>

                            <ul>
                            <li>	Social media: Facebook, Instagram, Twitter</li>
                            <li>	Google services</li>
                            <li>	Streaming services: Hulu, Netflix, Amazon Prime, Disney Hotstar.</li>
                            <li>	Email services: Yahoo, Gmail, More.</li>

                            </ul>
                            <p className="mb-2 text-justify">These are a few gadgets and services we highlighted to let you for which we provide our helpful information. This list of devices or services is much longer.</p>
                            <p className="mb-2 text-justify">To sum up, we are your go-to website for quick assistance with the latest gadgets, Computer accessories, online services, and more. We offer help in article form, keeping the research, outcomes, and consequences in mind. You can freely trust and use our GUIDES to be assisted soon.</p>
                            



                        
                        </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </section>
   </>
  )
}

export default About